import React, { Component } from "react";
import "./App.scss";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../src/redux/store";
import "react-toastify/dist/ReactToastify.css";
// import "react-select/dist/react-select.min.css";
import { getBrowserName } from "./config/Helper";
import AppRoute from "./AppRoute";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      browser: "",
    };
    document.addEventListener("click", this.clickHandler);
    // document.addEventListener("mousemove", this.mousemoveHandler);
  }
  componentDidMount() {
    if (getBrowserName() !== "Chrome") {
      this.setState({
        browser: "other",
      });
      document.body.classList.add("browser-check");
    }
  }
  hidePopup = () => {
    this.setState({
      browser: "",
    });
    document.body.classList.remove("browser-check");
  };

  render() {
    return (
      <Provider store={store}>
        {this.state.browser === "other" ? (
          <div className="chrome-msg">
            <span>Works best in Chrome</span>
            <i
              className="fa fa-times"
              onClick={() => this.hidePopup()}
              aria-hidden="true"
            ></i>
          </div>
        ) : (
          ""
        )}

        <PersistGate loading={null} persistor={persistor}>
          <AppRoute></AppRoute>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;

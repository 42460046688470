import { combineReducers } from "redux";
import auth from "./auth.reducers";
import storage from "./storage.reducer";
import { EnterpriseData } from "./enterpriseReducer";
const rootReducer = combineReducers({
  auth,
  storage,
  EnterpriseData,
});

export default rootReducer;

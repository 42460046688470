import * as types from "../actions/auth.action";
const INITIAL_STATE = {
  token: null,
  usercredentials: null,
  northwelluser: null,
  stsToken: null,
  isPBCSamlLoginDone: false,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.NORTHWELLUSER:
      return {
        ...state,
        northwelluser: action.payload.northwelluserobj,
      };
    case types.USER_CREDENTIALS:
      return {
        ...state,
        userCredentials: action.payload.userObj,
      };
    case types.SAVE_ACCESSTOKEN:
      return {
        ...state,
        token: action.payload.tokenObj,
      };
    case types.STSTOKEN:
      return {
        ...state,
        stsToken: action.payload.tokenObj,
      };
    case types.IS_PBCSAMLOGINDONE:
      return {
        ...state,
        isPBCSamlLoginDone: true,
      };
    case types.AUTH_LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    case types.ENTP_SELCTION:
      return {
        ...state,
        token: action.payload.selectedValue,
      };
    default:
      return state;
  }
}

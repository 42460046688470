import { toast } from "react-toastify";
import { validationMessagesForOrganization } from "./ValidationMessage";

export const PageConfig = {
  PAGE_SIZE: 10,
};

export const ApiErrorHandling = (error) => {
  if (error.status === 401) {
    return;
  }
  if (error.data?.settings) {
    toast.error(error.data.settings.message);
  } else {
    toast.error("Something went wrong");
  }
};

export const ApiSuccessHandling = (message) => {
  toast.success(message);
};

export const ErrorMessage = (name) => {
  let message;
  switch (name) {
    case "HOSPITAL_NAME":
      message = validationMessagesForOrganization.HOSPITAL_NAME;
      break;
    case "DEPARTMENT_NAME":
      message = validationMessagesForOrganization.DEPARTMENT_NAME;
      break;
    case "HOSPITAL_CODE":
      message = validationMessagesForOrganization.HOSPITAL_CODE;
      break;

    case "ADDRESS":
      message = validationMessagesForOrganization.ADDRESS;
      break;

    case "CITY_NAME":
      message = validationMessagesForOrganization.CITY_NAME;
      break;

    case "ZIP_CODE":
      message = validationMessagesForOrganization.ZIP_CODE;
      break;

    case "ZIP_CODE_VALID":
      message = validationMessagesForOrganization.ZIP_CODE_VALID;
      break;

    case "STATUS":
      message = validationMessagesForOrganization.STATUS;
      break;
    case "SETTINGS":
      message = validationMessagesForOrganization.ORGANISATION_SETTINGS;
      break;
    case "LATITUDE":
      message = validationMessagesForOrganization.LATITUDE_ERROR;
      break;
    case "LONGITUDE":
      message = validationMessagesForOrganization.LONGITUDE_ERROR;
      break;
    default:
      message = "";
      break;
  }
  return message;
};

export const getUnformattedSearch = (val) => {
  let sData = val;
  let firstChar = val.charAt(0);
  if (!firstChar.match(/[a-z]/i)) {
    let txt = val;
    let numb = txt.match(/\d/g);
    if (numb) {
      numb = numb.join("");
      sData = numb;
    }
  }
  return sData;
};

export const getBrowserName = () => {
  var navigator = window.navigator;

  //var nVer = navigator.appVersion;
  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;

  // In Opera, the true version is after "Opera" or after "Version"
  if (nAgt.indexOf("Opera") !== -1) {
    browserName = "Opera";
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if (nAgt.indexOf("MSIE") !== -1) {
    browserName = "Microsoft Internet Explorer";
  }
  // In Chrome, the true version is after "Chrome"
  else if (nAgt.indexOf("Chrome") !== -1) {
    browserName = "Chrome";
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if (nAgt.indexOf("Safari") !== -1) {
    browserName = "Safari";
  }
  // In Firefox, the true version is after "Firefox"
  else if (nAgt.indexOf("Firefox") !== -1) {
    browserName = "Firefox";
  }

  return browserName;
};

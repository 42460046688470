export const IS_PBCSAMLOGINDONE = "IS_PBCSAMLOGINDONE";
export const USER_CREDENTIALS = "USER_CREDENTIALS";
export const NORTHWELLUSER = "NORTHWELLUSER";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SAVE_ACCESSTOKEN = "SAVE_ACCESSTOKEN";
export const ENTP_SELCTION = "ENTP_SELCTION";
export const STSTOKEN = "STSTOKEN";

export const pbcsamllogindone = () => {
  return {
    type: IS_PBCSAMLOGINDONE,
  };
};

export const saveusercredentials = (userObj) => {
  return {
    type: USER_CREDENTIALS,
    payload: { userObj },
  };
};
export const savenorthwelluserobj = (northwelluserobj) => {
  return {
    type: NORTHWELLUSER,
    payload: { northwelluserobj },
  };
};
export const saveaccesstoken = (tokenObj) => {
  return {
    type: SAVE_ACCESSTOKEN,
    payload: { tokenObj },
  };
};
export const saveStsToken = (tokenObj) => {
  return {
    type: STSTOKEN,
    payload: { tokenObj },
  };
};
export const logout = () => {
  return {
    type: AUTH_LOGOUT,
  };
};

import { ENTP_SELECTION } from "../actions/enterpriseAction";
const entp = {
  entpSelectData: [],
};

export const EnterpriseData = (state = entp, action) => {
  switch (action.type) {
    case ENTP_SELECTION:
      entp.entpSelectData = [];
      return Object.assign({}, state, { entpSelectData: action.payload });

    default:
      return state;
  }
};

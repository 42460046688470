export const ENTP_SELECTION = "ENTP_SELECTION";

const getDetails = (todo) => ({
  type: ENTP_SELECTION,
  payload: {
    ...todo,
  },
});

export const enterPriseSelection = (data) => async (dispatch) => {
  dispatch(getDetails(data));
};

import React from "react";
import axios from "axios";

import { store } from "../redux/store";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import jwt_decode from "jwt-decode";
import * as actions from "../redux/actions/auth.action";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_URL + "/graphql",
});

const authLink = setContext(async (_, { headers }) => {
  let accessToken = await getGraphQLToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const getGraphQLToken = async () => {
  let redux_store = store.getState();
  let northwelluser = JSON.parse(redux_store.auth?.northwelluser);
  let stsTokenManager = northwelluser?.user?.stsTokenManager;
  // let tokenAuth = northwelluser?.user?.stsTokenManager.accessToken;
  // get the authentication token from local storage if it exists

  // const token = tokenAuth;
  if (stsTokenManager) {
    const accessTokenExpTime = jwt_decode(stsTokenManager.accessToken).exp;
    const now = parseInt(Date.now() / 1000);
    if (now >= accessTokenExpTime) {
      let newStsTokens = await renewToken();
      stsTokenManager = newStsTokens;
      northwelluser.user.stsTokenManager = newStsTokens;
      // store.dispatch(actions.saveStsToken(stsTokenManager));
      store.dispatch(
        actions.savenorthwelluserobj(JSON.stringify(northwelluser))
      );
      return newStsTokens.accessToken;
    }
    return stsTokenManager.accessToken;
  } else if (stsTokenManager !== null && stsTokenManager !== undefined) {
    store.dispatch(actions.logout());
    window.location.replace("/login");
  }
};

const renewToken = async () => {
  let redux_store = store.getState();
  let northwelluser = JSON.parse(redux_store.auth?.northwelluser);
  let stsTokens = northwelluser?.user?.stsTokenManager;
  var refreshToken = stsTokens.refreshToken;
  var bodyFormData = {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  };
  return axios({
    method: "post",
    url: `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASEAPIKEY}`,
    data: bodyFormData,
    config: { headers: { "Content-Type": "multipart/form-data" } },
  })
    .then((response) => {
      var stsTokenManager = {
        apiKey: process.env.REACT_APP_FIREBASEAPIKEY,
        refreshToken: response.data.refresh_token,
        accessToken: response.data.access_token,
        expirationTime: response.data.expires_in,
      };
      store.dispatch(actions.saveStsToken(stsTokenManager));
      return stsTokenManager;
    })
    .catch((errorNew) => {
      console.log("refresh token error " + errorNew);
      sessionStorage.clear();
      localStorage.clear();
      store.dispatch(actions.logout());
      window.location.replace("/login");
    });
};

export default function WithApolloClient(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <ApolloProvider client={client}>
          <WrappedComponent {...this.props} />
        </ApolloProvider>
      );
    }
  };
}

export const validationMessagesForOrganization = {
  NAME: "Please enter full name",
  HOSPITAL_NAME: "Please enter hospital name",
  HOSPITAL_CODE: "Please enter hospital code",
  DOMAIN: "Please enter domain name",
  VALID_DOMAIN: "Please enter valid domain name",
  LOGIN_SYSTEM: "Please select login system",
  URL: "Please enter valid url ",
  FIRST_NAME: "Please enter first name ",
  LAST_NAME: "Please enter last name ",
  EMAIL: "Please enter email ",
  EMAIL_VALID: "Please enter valid email ",
  DEGREE: "Please enter degree name",
  TITLE: "Please enter title",
  PHONE_NUMBER: "Please enter phone number",
  PHONE_NUMBER_VALID: "Please enter valid phone number",
  OFFICE_ADDRESS: "Please enter office address",
  ADDRESS: "Please enter address",
  CITY_NAME: "Please enter city name",
  ZIP_CODE: "Please enter zip code",
  ZIP_CODE_VALID: "Please enter valid zip code",
  DEPARTMENT: "Please enter department",
  ID: "Please enter id",
  DOB: "Please enter date of birth",
  DOB_VALID: "Please enter valid date of birth",
  HOURS: "Hours should be greater than 0",
  STATUS: "Please select status",
  DEPARTMENT_NAME: "Please enter department name",
  ORGANISATION_SETTINGS: "Settings text should be in correct JSON format",
  LATITUDE_ERROR: "Please enter valid latitude",
  LONGITUDE_ERROR: "Please enter valid longitude",
};

import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import WithApolloClient from "./wrapper/apolloWrapper";
const loading = () => <div>Loading</div>;

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));

class AppRoute extends Component {
  requireAuth = () => {
    return localStorage.getItem("login") === "yes" ? true : false;
  };
  render() {
    const containerStyle = {
      zIndex: 1999,
    };
    const SecretRoute = ({ ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          this.requireAuth() ? (
            <DefaultLayout {...props} />
          ) : (
            document.addEventListener("readystatechange", (event) => {
              if (event.target.readyState === "complete") {
                window.location.href = "/login";
              }
            })
          )
        }
      />
    );
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          style={containerStyle}
        />
        <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />

              <SecretRoute
                path="/"
                name="Home"
                render={(props) => <DefaultLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    northwelluser: state.auth.northwelluser,
  };
};

// export default App;
export default WithApolloClient(connect(mapStateToProps, {})(AppRoute));
